.spin{
    --w:130px;
    --h:130px;
    width: calc( var(--w) / 4);
    height: calc(var(--h) / 4); 
    border-radius: calc(var(--w) - var( --w ) / 2 );  
    animation: spiner 2s;
    background:rgb(4, 54, 27); color: rgb(2, 15, 44);
    border: 2px dashed #b42a17;
  
    animation-timing-function:linear;
    transform: rotate(240deg);
    animation-iteration-count: infinite;
 }

@keyframes spiner{
    
    from{
        transform: rotate(360deg);
        border:2px dashed #666366;
        box-shadow: 0px 0px 10px #26a0da, 0px 0px 40px#26a0da, 0px 0px 80px #26a0da;
        transition-delay: 0.2s;

    }
to{
    transform: rotate(0deg);
    box-shadow: 0px 0px 10px #F4D03F, 0px 0px 40px#585358, 0px 0px 80px #5c595b; transition-delay: 1s;

}
} 

