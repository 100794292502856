.landing{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3%;
    flex-wrap: wrap;
}

.simulatorInvitation{
    background-color: rgb(90, 81, 81);
    color: rgb(194, 189, 189);
}

.docsInvitation{
    width: 100%;
    background-color: rgb(69, 85, 69);
    color: rgb(194, 189, 189);
}

.runLocallyLanding{
    background-color: rgb(26, 27, 26);
    color: rgb(194, 189, 189);
}

.simInviteMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(49, 45, 45, 0.9);
    border: 2px solid rgb(90, 82, 82);
    border-radius: 10px;
    width: 80%;
    height: 60%;
    text-align: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    flex-wrap: wrap;
}

.darkenLayer{
    background-color: rgba(8, 8, 8, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


