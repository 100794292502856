.article{
    width: 70vw;
    margin: auto;
}

p, li{
    line-height: 1.6;
    color: rgb(31, 30, 30);
}

table{
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
}

.diagramArea{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.diagramArea{
    font-size: 15px;
    margin: auto;
}

h2 {
    margin-top: 70px;
}

h3, h4 {
    margin-top: 40px;
}

.disclaimer{
    height: auto;
}

img{
    margin-top: 50px;
    margin-bottom: 70px;
    width: 100%;
}

p, li{
    color: rgb(34, 34, 34);
    font-size: 1.2em;
}

h1, h2, h3, h4{
    font-size: 2em;
}

.centerImage{
    margin: auto;
}

.centerImageContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin: auto;
}

.max400WidthImage{
    max-width: 400px;
}

.max800WidthImage{
    max-width: 800px;
}
