.redx {
    position: relative;
    height: 40px; /* this can be anything */
    width: 40px;  /* ...but maintain 1:1 aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40%; 
}

.redx::before,
.redx::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 8px; /* cross thickness */
    background-color: rgb(204, 19, 19);
}

.redx::before {
    transform: rotate(45deg);
}

.redx::after {
    transform: rotate(-45deg);
}