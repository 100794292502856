.topBlockChainPanel{
    background-color: #a4a6a8;
    padding-bottom: 1vh;
}

.middleBlockChainPanel{
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: #d3d5d6;
}

.json-tree-view{
    width: 100%;
}

