.analyticsContainer{
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: auto;
}

.basicBlockchainTitle{
    background-color: rgb(66, 90, 73);
    padding: 5px;
    border-radius: 5px;
    color: white;
    text-align: center;
}

.analyticsChild{
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    color: rgb(56, 55, 55);
    gap:10px;
    width: 100%;

    padding: 3px;
}

.nameOfItem{
    margin: auto;
}

.valueOfItem{
    text-align: end;
}

.invalidBlockItem{
    color: red;
}

.validBlockItem{
    color: green;
}