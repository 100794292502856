.nonceInputSlider{
    height: 300px;
    margin-bottom:5vh;

}


.nonceContainer{
    background-color: rgba(23, 58, 21, 0.3);
    padding-top: 20px;
}

.highlightTwoZeros{
    font-size: 24px;
    color: rgb(6, 110, 6);
}

.success{
    background-color: #5cb85c ;
    border: 0.5px solid rgba(0,0,0,0.2);
    border-radius: 2px;
    padding: 5px;
    color:#383a3d;
    height: 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.danger{
    background-color:  #d9534f  ;
    border: 0.5px solid rgba(0,0,0,0.2);
    border-radius: 2px;
    padding: 5px;
    color:#383a3d;
    height: 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: 100%;
}

.nonceform{
    max-width: 500px;
    margin-top: 3vh;
}

.interactivearea{
    margin-top: 3vh;
}