.greenButton{
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 35px;
    border-radius: 4px;
    background-color: #41af59;
}

.greenButton:hover{
    cursor: pointer;
    background-color: #246332;
}

.greenButton:active{
    background-color: #102c16;;
}

.redButton{
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 35px;
    border-radius: 4px;
    background-color: #af5141;
}

.redButton:hover{
    cursor: pointer;
    background-color: #632d24;
}

.redButton:active{
    background-color: #2c1410;;
}

.correctAnswerArea{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.cta{
    height: 10vh;
    width: 30vh;
    background-color: #435c23;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    color: rgb(214, 200, 200);
    border: 1px solid rgba(7, 6, 6, 0.3);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.cta:hover{
    background-color: #314219;
    cursor: pointer;
}


.cta:active{
    background-color: #222e11;
    cursor: pointer;
}