.fetchArea{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: auto;
    min-height: 10vh;
    align-items: center;
    background-color: rgba(23, 58, 21, 0.3);
    position: relative;
    flex-wrap: wrap;
}

.updateStatus{
    position: absolute;
    bottom:0;
    right: 0;
    font-size: 10px;
    color: rgb(66, 66, 66);
}

.fetchedArea{
    position: relative;
    font-size: 12px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}

.fetchedAreaItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}

.outputArea{
    width: 50vh;
}

.interactivearea{
    height: 10vh;
}

