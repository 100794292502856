.terminal{
    background-color: black;
    color: green;
    font-weight: bold;
    font-size: 12px;
    width: 50%;
    height: 4vh;
    padding-left: 10px;
    padding-top: 20px;
    border: 2px solid rgb(88, 78, 78);
    border-radius: 10px;
    position: relative;
}

.clipboardIcon{
    position: absolute;
    right: 0;
}

.terminal:hover{
    cursor: pointer;
    background-color: rgb(39, 39, 39);
}

.copiedMessage{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    font-size: 10px;
}