

.App-header {
  background-color: #172922;
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 1vmin);
  color: white;
  gap: 10vh;
  padding: 20px;
  width: 20vw;
}

.App-footer {
  background-color: #000000;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
  bottom: 0px;
}

