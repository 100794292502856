.blockchainJSONPanel{
    width: 100%;
    border: 2px solid rgb(145, 142, 142);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: black;
}

.jsonTreePanelTitle{
    color: rgb(223, 215, 215);
    margin: auto;
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
}
.jsonTreeDevider{
    margin-bottom: 15px;
}