.hashplaycontainer{
    background-color: rgba(23, 58, 21, 0.3);
    padding-top: 1vh;
    height: 20vh;
}

.hashplayoutputtext{
    color: rgb(43, 42, 42);
    inline-size: 100%;
    overflow-wrap: break-word;
}