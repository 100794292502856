.difficultyEntry{
    width: 280px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 10px;
    margin-top: 20px;
}

.difficulty{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 10px 10px;
    padding: 20px 20px;
    background-color: #a4a6a8;
}

.difficultySelect{
    width:100%;
    height:40px;
    border-top: none;
    border-right: none;
    border-left: none;
    text-align: center;
    border-bottom: 1px solid rgba(3, 3, 3, 0.8);
    color: rgba(252, 252, 252, 0.8);
    background-color: rgba(0,0,0,0);
}

.difficultyEntry:hover, .difficultySelect:hover{
    cursor: pointer;
    background-color: rgb(77, 75, 75);
}

.difficultyTitle{
    font-size: 12px;
    color: rgb(240, 239, 239);
}

.difficultySelect option{
    width: 100%;
    text-align: center;
}

.warning{
    background-color: #e0ec74;
    border: 0.5px solid rgba(0,0,0,0.2);
    border-radius: 2px;
    padding: 5px;
    color:#383a3d;
    height: 60px;
    border-radius: 4px;
    margin-top: 9px;
}

.diffcultyWarning{
    font-size: 12px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.difficultyTextField{
    width: 25ch;
}
