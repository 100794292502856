.block{
    width: 280px;
    height: auto;
    background-color: #f8f9fa;
    border: 0.5px solid rgb(124, 124, 124);
    border-radius: 4px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 10px;
    font-size: 10px;
    min-height: 360px;
}

.space{
    margin-top: 5px;
}

.messagetextarea{
    width: 90%;

}

.messageinput{
    width: 100%;
    border-radius: 8px;
    margin-top:5px;
    resize: none;
}

.valid{
    background-color: #28a745;
}


.invalid{
    background-color: #dc3545;
}

.mineButton{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #153f17;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #153f17;
    font-size: 24px;
    color: #aeb0b3;
    gap: 30px 30px;
    padding-left: 35%;
    align-items: center;
    border-radius: 4px;
    height: 40px;
}

.mineButton:hover{
    cursor: pointer;
    background-color: darkgreen;
}

hr{
    color: #246332;
}

.blocknumber{
    font-size: 24px;
}

.info{
    background-color: #39C0ED;
    border: 0.5px solid rgba(0,0,0,0.2);
    border-radius: 2px;
    padding: 5px;
    color:#383a3d;
    height: 10vh;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}